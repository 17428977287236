(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.vehicles.guests.list.controller:GuestsListCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles.guests.list')
    .controller('GuestsListCtrl', GuestsListCtrl);

  function GuestsListCtrl($scope, $state, $stateParams, $filter, PAGE_SIZE, Restangular, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicle = null;
    vm.guests = [];

    // md-data-table
    vm.meta = {
      limit: PAGE_SIZE,
      page: $stateParams.p,
      total: 0,
      query: '',
      order: 'name'
    };
    vm.search = search;
    vm.query = query;
    vm.paginate = paginate;
    vm.reorder = reorder;

    // Drop the case for a query string search
    if ($stateParams.q !== '') {
      vm.meta.query = $stateParams.q.toLowerCase();
    }

    // Run initial search
    search();

    // Run the search when the state changes
    // In the state (set in the routes file) we use reloadOnSearch = false so we can change states as the user types in the search field without losing focus on the input field
    $scope.$on('$locationChangeSuccess', function () {
      search();
    });

    // Run the search
    function search() {

      var args = {
        search: vm.meta.query,
        limit: vm.meta.limit,
        page: vm.meta.page,
        total: 'true',
        inc: 'Vehicle'
      };

      // Get the vehicle if required
      if ($stateParams.vehicleId && !vm.vehicle) {
        Restangular.one('vehicles', $stateParams.vehicleId).get().then(function (response) {
          vm.vehicle = response.data;
          runSearch();
        });
      } else {
        runSearch();
      }

      function runSearch() {

        var rest;

        if ($stateParams.vehicleId) {
          rest = Restangular.one('vehicles', vm.vehicle.id);
        } else {
          rest = Restangular.one('events', vm.event.id);
        }
        rest.all('guests').getList(args).then(function (guests) {
          vm.meta.total = guests.meta.total;

          for (var i = 0; i < guests.length; i++) {
            guests[i].cost = $filter('dollars')(guests[i].cost);
          }

          vm.guests = guests;
        });
      }
    }

    // Callback for query changes
    function query() {
      vm.meta.page = 1; // reset search to first page for new query
      $state.go('.', {q: vm.meta.query, p: vm.meta.page});
    }

    // Callback for md-on-paginate
    function paginate(page, limit) {
      $state.go('.', {q: $stateParams.q, p: page});
    }

    function reorder(order) {
      console.log(order);
    }
  }
}());
